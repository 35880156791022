<script setup></script>

<template>
    <div class="flex justify-center w-full mt-10">
        <h1
            class="text-4xl font-semibold tracking-wide text-center text-black uppercase text-shadow-custom md:text-7xl">
            Connect Collaborate Create
        </h1>
    </div>
    <section class="flex flex-col justify-center pb-10">
        <div class="w-2/3 mx-auto mt-10">
            <p class="font-semibold text-center uppercase">
                AT SPICY GIGS, WE MAKE IT SIMPLE FOR CREATORS, PRODUCERS, AND STUDIOS TO CONNECT AND COLLABORATE IN ONE
                SECURE, EASY-TO-USE PLATFORM. NO MORE ​JUGGLING MULTIPLE SOCIAL MEDIA OUTLETS—EVERYTHING YOU NEED, FROM
                CALENDAR MANAGEMENT TO GIG BOOKINGS, IS RIGHT HERE. OUR INTUITIVE SEARCH ​ENGINE MAKES IT QUICK AND EASY
                TO FIND AVAILABLE CREATORS IN YOUR AREA, SO YOU CAN START COLLABORATING RIGHT AWAY
            </p>
        </div>
        <div class="flex w-full gap-6 mt-10 lg:flex-row">
            <div class="flex flex-col w-11/12 gap-4 mx-auto lg:flex-row">
                <div class="flex-1">
                    <img src="@images/hero1.webp" alt="" class="object-contain w-full border border-black" />
                </div>
                <div class="flex-1">
                    <img src="@images/hero2.webp" alt="" class="object-contain w-full border border-black" />
                </div>
            </div>
        </div>
    </section>
</template>

<style>
.text-shadow-custom {
    text-shadow: 2px 1px 1px rgb(7, 238, 207);
    opacity: 0.7;
}
</style>
